<template>
  <v-container
    class="mt-3"
    fluid
  >
    <v-row v-if="$auth.user().client.services.filter(x => x.name.includes('OneChoice Reports')).length > 0">
      <v-col
        cols="12"
        lg="4"
      >
        <monthly-lab-results
          hide-trend
        />
      </v-col>
      <v-col
        cols="12"
        lg="4"
      >
        <daily-lab-results
          :days="7"
          hide-trend
        />
      </v-col>

      <v-col
        cols="12"
        lg="4"
      >
        <sources-chart
          hide-trend
        />
      </v-col>
    </v-row>


    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <stats
          cols="6"
          :metrics="[
            'providers',
            'facilities',
            'patients',
            'microbes'
          ]"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <data-map />
      </v-col>
    </v-row>

    <!--
    <v-row v-if="$auth.user().client.services.map(x => x.name).includes('Precision ASP')">
      <v-col
        cols="12"
        lg="6"
      >
        <material-chart-card
          id="topTenDrugs"
          :data="asp.drugs"
          :options="{
            horizontalBars: true,
            seriesBarDistance: 14,
            reverseData: true,
            chartPadding: {
              top: 14,
              right: 20,
              bottom: 0,
              left: 65,
            },
            axisX: {
              labelOffset: {
                x: -5,
                y: 3,
              },
              height:80,
              labelInterpolationFnc: (value) => value >= 1000 ? this.$options.filters.abbreviate(value) : value,
            }
          }"
          xloading="loading.topTenDrugs"
          color="indigo darken-1"
          type="Bar"
        >
          <h4 class="text-h6 font-weight-light">
            Top 10 Currently Prescribed Antibiotics
          </h4>

          <template #actions>
            <v-icon
              class="mr-2"
              small
            >
              mdi-clock-outline
            </v-icon>
            <span class="text-caption grey--text font-weight-light">{{ $t('updated') }} {{ $moment(asp.time).diff() | duration('humanize', true) }}</span>
            <v-spacer />
            <v-btn
              icon
              x-small
              @click="fetchStats(true)"
            >
              <v-icon
                small
              >
                mdi-reload
              </v-icon>
            </v-btn>
          </template>
        </material-chart-card>
      </v-col>

      <v-col
        cols="12"
        lg="6"
      >
        <material-chart-card
          id="monthlyPrescriptions"
          :data="asp.monthly_prescriptions"
          :options="{
            lineSmooth: this.$chartist.Interpolation.cardinal({
              tension: 5,
            }),
            stackBars:true,
            showArea: true,
            showPoint: false,
            fullWidth: true,
            // low: 0,
            // high: 50, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
            chartPadding: {
              top: 20,
              right: 20,
              bottom: 0,
              left: 6,
            },
            axisX: {
              labelOffset: {
                x: -10,
                y: 3,
              },
            },
            axisY: {
              labelOffset: {
                y: 5,
              },
              labelInterpolationFnc: (value) => value >= 1000 ? this.$options.filters.abbreviate(value) : value,
            },
            axisX: {
              xdivisor: 5,
              labelInterpolationFnc: function(value) {
                return $moment.unix(value).format('MMM D');
              }
            }
          }"
          xloading="loading.monthlyPrescriptions"
          color="purple darken-1"
          type="Line"
          sub-title="Last 10 Days"
        >
          <h4 class="text-h6 font-weight-light">
            New Antibiotic Prescriptions
          </h4>

          <template #actions>
            <v-icon
              class="mr-2"
              small
            >
              mdi-clock-outline
            </v-icon>
            <span class="text-caption grey--text font-weight-light">{{ $t('updated') }} {{ $moment(asp.time).diff() | duration('humanize', true) }}</span>
            <v-spacer />
            <v-btn
              icon
              x-small
              @click="fetchStats(true)"
            >
              <v-icon
                small
              >
                mdi-reload
              </v-icon>
            </v-btn>
          </template>
        </material-chart-card>
      </v-col>
    </v-row>

    <v-row v-if="$auth.user().client.services.map(x => x.name).includes('Precision ASP')">
      <v-col
        xcols="12"
        xsm="6"
        xlg="3"
      >
        <material-stats-card
          color="red"
          icon="fa-light fa-user-doctor"
          title="Providers"
          xsub-text="Flagged Prescriptions"
          ctitle="Providers with Flagged Prescriptions"
          :value="asp.providers"
          csub-icon="mdi-file-outline"
          :loading="loading.lab_results && !lab_results.total"
        />
      </v-col>

      <v-col
        xcols="12"
        xsm="6"
        xlg="3"
      >
        <material-stats-card
          color="orange"
          icon="fa-light fa-bed-pulse"
          title="Patients"
          xsub-text="Flagged Prescriptions"
          :value="asp.patients"
          csub-icon="mdi-calendar"
          scub-text="Last 30 Days"
          :loading="loading.approval_time"
          @refresh="fetchStats(true)"
        />
      </v-col>

      <v-col
        xcols="12"
        xsm="6"
        xlg="3"
      >
        <material-stats-card
          color="green"
          icon="fal fa-clipboard-prescription"
          title="Prescriptions"
          :value="asp.prescriptions"
          xsub-icon="mdi-calendar-month"
          xsub-text="(revenue.revenue / $moment().diff($moment().startOf('month'), 'hours')) * $moment().daysInMonth() * 24 | currency"
          :loading="loading.revenue"
          @refresh="fetchStats(true)"
        />
      </v-col>

      <v-col
        xcols="12"
        xsm="6"
        xlg="3"
      >
        <material-stats-card
          color="info"
          icon="fal fa-calendar-alt"
          title="Average Duration"
          :value="Math.round(asp.average_duration) | pluralize('day', { includeNumber: true })"
          xsub-icon="fal fa-badge-check"
          xsub-text="autoStats"
          xloading="loading.approval_time"
          @refresh="fetchStats(true)"
        />
      </v-col> -->

    <v-row v-if="$auth.user().client.services.map(x => x.name).includes('Precision ASP')">
      <v-col
        cols="12"
        lg="6"
      >
        <material-card
          color="pink darken-4"
          title="C.diff Risk"
          text="Prescriptions written in the last 30 days"
          icon="fa fa-file"
        >
          <providers-table
            hide-default-footer
            hide-default-header
            class="elevation-0"
            sort-by="score_cdiff_risk"
            :sort-desc="true"
            :show-filters="false"
            :show-expand="false"
            :items-per-page="10"
            :headers="[
              {
                text: this.$t('last name'),
                value: 'last_name',
              },
              {
                text: this.$t('first name'),
                value: 'first_name',
              },
              {
                text: 'Prescriptions',
                value: 'prescriptions_count',
              },
              {
                text: 'C.diff Risk',
                value: 'score_cdiff_risk',
                align: 'right',
              },
            ]"
          />
        </material-card>
      </v-col>
      <v-col
        cols="12"
        lg="6"
      >
        <material-card
          color="blue darken-4"
          title="Providers Not Logged In Today"
          text="With active prescriptions"
          icon="fa fa-file"
        >
          <providers-table
            xhide-default-footer
            hide-default-header
            class="elevation-0"
            xsort-by="last_login"
            :filters="{
              asp_no_login: $moment().format()
            }"
            :sort-desc="true"
            :show-filters="false"
            :show-expand="false"
            :items-per-page="8"
            :headers="[
              {
                text: 'ASP Alerts',
                value: 'asp_sms_alerts',
              },
              {
                text: this.$t('last name'),
                value: 'last_name',
              },
              {
                text: this.$t('first name'),
                value: 'first_name',
              },
              {
                text: 'Prescriptions',
                value: 'active_prescriptions_count',
              },
              {
                text: 'Last Login',
                value: 'last_login',
              },
            ]"
          />
        </material-card>
      </v-col>
    </v-row>
    <!--
      <v-tour
        name="firstLogin"
        :steps="steps"
      />
    -->
  </v-container>
</template>

<script>
  export default {
    components: {
      ProvidersTable: () => import('@/components/tables/ProvidersTable.vue'),
      MonthlyLabResults: () => import('@/views/dashboards/widgets/MonthyLabResults.vue'),
      DailyLabResults: () => import('@/views/dashboards/widgets/DailyLabResults.vue'),
      SourcesChart: () => import('@/views/dashboards/widgets/SourcesChart.vue'),
      DataMap: () => import('@/views/dashboards/widgets/DataMap.vue'),
      Stats: () => import('@/views/dashboards/widgets/Stats.vue'),
    },
    metaInfo: {
      title: 'Dashboard',
    },
    data () {
      return {
        steps: [
          {
            content: `Welcome to Arkstone`,
          },
          {
            target: '#left_nav_asp_overview',  // We're using document.querySelector() under the hood
            header: {
              title: 'Get Started',
            },
            params: {
              placement: 'right', // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
            },
            content: `Discover <strong>Vue Tour</strong>!`,
          },
          {
            target: '.v-step-1',
            content: 'An awesome plugin made with Vue.js!',
          },
          {
            target: '[data-v-step="2"]',
            content: 'Try it, you\'ll love it!<br>You can put HTML in the steps and completely customize the DOM to suit your needs.',
            params: {
              placement: 'top', // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
            },
          },
        ],
        asp: {},
        stats: {},
        loading: {
          lab_results: false,
          activity: false,
          approval_time: false,
          revenue: false,
        },
        monthlyResultsChart: {
          time: null,
          data: {
            labels: [],
            series: [
              [],
            ],
          },
          options: {
            lineSmooth: this.$chartist.Interpolation.cardinal({
              tension: 5,
            }),
            stackBars:true,
            showArea: true,
            showPoint: false,
            fullWidth: true,
            // low: 0,
            // high: 50, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
            chartPadding: {
              top: 20,
              right: 20,
              bottom: 0,
              left: 6,
            },
            axisX: {
              labelOffset: {
                x: -10,
                y: 3,
              },
            },
            axisY: {
              labelOffset: {
                y: 5,
              },
              labelInterpolationFnc: (value) => value >= 1000 ? this.$options.filters.abbreviate(value) : value,
            },
          },
          responsiveOptions: [
            ['screen and (max-width: 640px)', {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc: function (value) {
                  return value[0]
                },
              },
            }],
          ],
        },
        monthlySourceChart: {
          time: null,
          data: {
            labels: [],
            series: [[]],
          },
          options: {
            // donut: true,
            // cxstretch: true,
            horizontalBars: true,
            seriesBarDistance: 10,
            // stackBars:true,
            // lineSmooth: this.$chartist.Interpolation.cardinal({
            //   tension: 0,
            // }),
            // low: 0,
            // high: 1000, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
            chartPadding: {
              top: 14,
              right: 20,
              bottom: 0,
              left: 40,
            },
            axisX: {
              labelOffset: {
                x: -5,
                y: 3,
              },
              height:80,
              labelInterpolationFnc: (value) => value >= 1000 ? this.$options.filters.abbreviate(value) : value,
            },
          },
        },
        dailyLabResultChart: {
          data: {
            labels: [],
            series: [[]],
          },
          options: {
            stackBars:true,
            chartPadding: {
              top: 20,
              right: 14,
              bottom: 14,
              left: 6,
            },
            axisX: {
              showGrid: false,
              labelOffset: {
                xx: -10,
                y: 3,
              },
            },
            axisY: {
              labelOffset: {
                y: 5,
              },
              labelInterpolationFnc: (value) => value >= 1000 ? this.$options.filters.abbreviate(value) : value,
            },
          },
          responsiveOptions: [
            ['screen and (max-width: 640px)', {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc: function (value) {
                  return value[0]
                },
              },
            }],
          ],
        },
      }
    },
    computed: {
    },
    created () {
      this.fetchAsp()
    },
    mounted: function () {
    //   this.$tours['firstLogin'].start()
    },
    methods: {
      fetchAsp (no_cache = false) {this.loading.asp = true
        this.axios.get('charts/asp', {
          params: {
            start_date: this.$moment().subtract(11, 'M').format('Y-M-01'),
            trend: 30,
            no_cache: no_cache,
          },
        })
          .then((res) => {
            this.asp = res.data
          })
          .catch(err => {
            this.$toast.error(err.response?.data?.message || err)
          })
          .finally(() => { this.loading.asp = false })
      },
      fetchStats (metrics, no_cache = false) {this.loading.asp = true
        this.axios.get('charts/stats', {
          params: {
            no_cache: no_cache,
            metrics: metrics,
          },
        })
          .then((res) => {
            for (const [key, value] of Object.entries(res.data)) {
              this.$set(this.stats, key, value)
            }
          })
          .catch(err => {
            this.$toast.error(err.response?.data?.message || err)
          })
          .finally(() => { this.loading.asp = false })
      },
    },
  }
</script>
<style lang="scss">
  #topTenDrugs {
    .ct-label.ct-vertical {
      font-size: 8.5pt;
      line-height: 1.1;
    }
    // .ct-series-a {
    //   .ct-bar {
    //     stroke: rgb(200, 255, 200);
    //   }
    // }
    // .ct-series-b {
    //   .ct-bar {
    //     stroke: rgb(255, 200, 200);
    //     // stroke-dasharray: 3px 1px;
    //   }
    // }
  }

  #topTenDrugs .ct-bar {
    stroke-width: 3% !important;
  }

  #monthlyPrescriptions .ct-bar {
    stroke-width: 3.25% !important;
  }
  #monthlyPrescriptions {
    .ct-point{
      stroke-width: 7px !important;
    }
    .ct-series-a {
      .ct-line, .ct-point {
      /* Set the colour of this series line */
        stroke: rgb(245, 152, 152);
        /* Control the thikness of your lines */
        stroke-width: 2px;
        /* Create a dashed line with a pattern */
        // stroke-dasharray: 10px 2px;
      }
      .ct-area {
        fill: rgb(245, 152, 152);
      }
    }
    .ct-series-b {
      .ct-line, .ct-point {
      /* Set the colour of this series line */
        stroke: rgb(132, 243, 132);
        /* Control the thikness of your lines */
        stroke-width: 3px;
      }
      .ct-area {
        fill: rgb(132, 243, 132);
      }
    }
  }
</style>
