var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"mt-3",attrs:{"fluid":""}},[(_vm.$auth.user().client.services.filter(x => x.name.includes('OneChoice Reports')).length > 0)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('monthly-lab-results',{attrs:{"hide-trend":""}})],1),_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('daily-lab-results',{attrs:{"days":7,"hide-trend":""}})],1),_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('sources-chart',{attrs:{"hide-trend":""}})],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('stats',{attrs:{"cols":"6","metrics":[
          'providers',
          'facilities',
          'patients',
          'microbes'
        ]}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('data-map')],1)],1),(_vm.$auth.user().client.services.map(x => x.name).includes('Precision ASP'))?_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('material-card',{attrs:{"color":"pink darken-4","title":"C.diff Risk","text":"Prescriptions written in the last 30 days","icon":"fa fa-file"}},[_c('providers-table',{staticClass:"elevation-0",attrs:{"hide-default-footer":"","hide-default-header":"","sort-by":"score_cdiff_risk","sort-desc":true,"show-filters":false,"show-expand":false,"items-per-page":10,"headers":[
            {
              text: this.$t('last name'),
              value: 'last_name',
            },
            {
              text: this.$t('first name'),
              value: 'first_name',
            },
            {
              text: 'Prescriptions',
              value: 'prescriptions_count',
            },
            {
              text: 'C.diff Risk',
              value: 'score_cdiff_risk',
              align: 'right',
            },
          ]}})],1)],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('material-card',{attrs:{"color":"blue darken-4","title":"Providers Not Logged In Today","text":"With active prescriptions","icon":"fa fa-file"}},[_c('providers-table',{staticClass:"elevation-0",attrs:{"xhide-default-footer":"","hide-default-header":"","xsort-by":"last_login","filters":{
            asp_no_login: _vm.$moment().format()
          },"sort-desc":true,"show-filters":false,"show-expand":false,"items-per-page":8,"headers":[
            {
              text: 'ASP Alerts',
              value: 'asp_sms_alerts',
            },
            {
              text: this.$t('last name'),
              value: 'last_name',
            },
            {
              text: this.$t('first name'),
              value: 'first_name',
            },
            {
              text: 'Prescriptions',
              value: 'active_prescriptions_count',
            },
            {
              text: 'Last Login',
              value: 'last_login',
            },
          ]}})],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }